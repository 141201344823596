import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Layout from '@/components/layout';
import PractitionersSearchSection from '@/components/practitioners/search-section';

const IndexPage: React.FunctionComponent = () => (
  <Layout>
    <PractitionersSearchSection />
    <section className="section mt-6">
      <div className="container is-max-desktop">
        <div className="columns">
          <div className="column has-text-centered">
            <StaticImage
              src="../assets/images/annuaire-etre-plus-logo-pink.png"
              alt="Etre plus logo pink"
            />
          </div>
          <div className="column has-text-justified">
            <p className="mb-3">
              Ce site unique en Belgique par sa richesse tant en quantité et
              qualité de professionnels, que par son contenu rédactionnel, est
              le résultat d’une longue tradition entretenue entre les nombreux
              thérapeutes et professionnels qui nous honorent de leur confiance
              et Être Plus, acteur historique et incontournable du mieux-être en
              Belgique depuis plus de 24 ans.
            </p>
            <p className="mb-5">
              Vous souhaitez consulter l&#39;édition papier de notre
              annuaire&nbsp;?
            </p>
            <p className="has-text-centered">
              <a
                className="button is-primary is-outlined"
                href="https://issuu.com/etreplus/docs/an22"
              >
                <span>Consulter l&#39;édition papier</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section my-6">
      <div className="container is-max-desktop">
        <div className="columns">
          <div className="column has-text-justified">
            <h2 className="title is-4">
              Vous êtes un professionnel du Bien Être&nbsp;?
            </h2>
            <p className="subtitle">Annuaire Plus: une formule unique</p>
            <ul>
              <li>
                <span className="has-text-primary">2 formats </span>: papier et
                internet
              </li>
              <li>
                <span className="has-text-primary">21 ans d’existence </span>
                ayant fait de l’Annuaire Être Plus un incontournable
              </li>
              <li>
                <span className="has-text-primary">250 rubriques </span>
                différentes
              </li>
              <li>
                <span className="has-text-primary">
                  2.500 points de distribution{` `}
                </span>
                dans toute la Belgique francophone (magasins bio, salles
                d’attente de thérapeutes, centres thérapeutiques, librairies,
                centres culturels,... )
              </li>
              <li>
                <span className="has-text-primary">
                  2.000 thérapeutes et professionnels{` `}
                </span>
                de médecines douces et développement personnel, de la santé
                naturelle, de la spiritualité́, de l’alimentation bio, de la
                transition et du développement durable
              </li>
              <li>
                <span className="has-text-primary">50.000 exemplaires </span>
                distribués
              </li>
              <li>
                <span className="has-text-primary">
                  200.000 lectrices et lecteurs{` `}
                </span>
                qui le consultent tout au long de l’année
              </li>

              <li>
                <span className="has-text-primary">
                  1.500.000 d’internautes{` `}
                </span>
                potentiels
              </li>
            </ul>
          </div>
          <div className="column has-text-centered">
            <StaticImage
              src="../assets/images/annuaire-etre-plus-digital-et-papier.png"
              alt="Etre plus magazine/annuaire papier et digital"
            />
            <a
              href="https://www.etreplus.be/annuaire-2023"
              className="button is-primary is-large mt-5"
            >
              <span>M&#39;inscrire pour 2023</span>
              <span className="icon">
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="section mb-6">
      <div className="container is-max-desktop">
        <div className="columns is-centered is-variable is-8 has-text-justified">
          <div className="column is-flex is-flex-direction-column is-justify-content-space-between">
            <div>
              <h2 className="title is-4">
                Vous souhaitez rester informés&nbsp;?
              </h2>
              <p className="mb-3">
                Rien de plus simple ! Inscrivez-vous à notre newsletter.
              </p>
            </div>
            <p className="has-text-centered">
              <a
                className="button is-primary is-outlined is-fullwidth"
                href="https://www.etreplus.be/contact"
              >
                <span>Notre newsletter</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </p>
          </div>
          <div className="column is-flex is-flex-direction-column is-justify-content-space-between">
            <div>
              <h2 className="title is-4">Vous avez des questions&nbsp;?</h2>
              <p className="mb-3">
                Nous serions ravi de répondre à vos questions concernant
                l&#39;Annuaire et vous conseiller sur la formule la plus adaptée
                à votre profil si vous désirez faire parti de nombreux
                spécialistes du bien être qui figurent déjà dans l&#39;Annuaire
                Plus.
              </p>
            </div>
            <p className="has-text-centered">
              <a
                href="https://www.etreplus.be/contact"
                className="button is-primary is-outlined is-fullwidth"
              >
                <span>Nous contacter</span>

                <span className="icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
export default IndexPage;
